import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Checkbox, FormControl} from "@material-ui/core";
import {CartContext} from "../../contexts/CartContext";

const ProductBlock = (props) => {
    const cartContext = useContext(CartContext);

    const [quantity, setQuantity] = useState((cartContext.products[props.block.id]) ? cartContext.products[props.block.id].quantity : props.block.minQuantity);

    const handleQuantityChange = function (event) {
        setQuantity(event.target.value);

        if (event.target.value === 0) {
            cartContext.removeProduct(props.block.id)
        } else {
            cartContext.updateProduct(props.block.id, props.block.displayName, event.target.value, props.block.price, props.block.quantityAvailable, props.block.amountSold, props.block.productActivityInProgressText)
        }
    }

    useEffect(() => {
        if (props.block.minQuantity === 1) {
            cartContext.updateProduct(props.block.id, props.block.displayName, 1, props.block.price, props.block.quantityAvailable, props.block.amountSold, props.block.productActivityInProgressText)
        }
    }, [])

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginBottom: "50px"
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        productLabel: {
            marginTop: "10px",
            marginLeft: "15px",
            marginRight: "5px"
        },
        productDescription: {
            marginRight: "5px"
        },
        quantity: {
            marginTop: '7px',
        },
        block: {
            ['@media (max-width:800px)']: {
                marginBottom: '20px'
            },
        }
    }));

    const classes = useStyles();


    function range(start, count) {
        return Array.apply(0, Array(count))
            .map((element, index) => index + start);
    }

    function handleCheckboxChange(event) {
        if (event.target.checked) {
            setQuantity(1);
            cartContext.updateProduct(props.block.id, props.block.displayName, 1, props.block.price, props.block.quantityAvailable, props.block.amountSold, props.block.productActivityInProgressText)
        } else {
            setQuantity(0);
            cartContext.removeProduct(props.block.id)
        }
    }

    return (
        <Grid container>
            {props.block.image && (
                <Grid item xs={12} sm={4}>
                    <img id={"product-image-" + props.block.id} height="100" src={props.block.image.url} className="product-image"/>
                </Grid>
            )}
            <Grid item xs={12} sm={8}>
            <Grid container className={classes.block}>
                <Grid item xs={2} sm={1}>
                    <FormControl>
                        {props.checkboxDisplay && (
                            <Checkbox id={"product-checkbox-" + props.block.id} name={"product-checkbox-" + props.block.id} value={props.block.id} onClick={handleCheckboxChange} />
                        )}
                        {!props.checkboxDisplay && (
                            <Select
                                className={classes.quantity}
                                value={quantity}
                                onChange={handleQuantityChange}
                                MenuProps={{
                                    disableScrollLock: true,
                                }}
                            >
                                {range(parseInt(props.block.minQuantity), props.block.maxQuantity).map(quantity => (
                                    <MenuItem value={quantity}>{quantity.toString()}</MenuItem>
                                ))}
                            </Select>
                        )}

                    </FormControl>
                </Grid>
                <Grid item xs={10} sm={11}>
                    <div className={classes.productLabel} dangerouslySetInnerHTML={{__html: props.block.displayName}} />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div id="product-description" className={classes.productDescription} dangerouslySetInnerHTML={{__html: props.block.description}} />
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    )
}

export default ProductBlock;
