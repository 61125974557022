import {createContext, useState} from "react";

export const CartContext = createContext(undefined);

export const CartProvider = ({ children }) => {
    const [products, setProducts] = useState({});
    const [donation, setDonation] = useState(undefined);
    const [total, setTotal] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [transactionFee, setTransactionFee] = useState(undefined);
    const [donationSettings, setDonationSettings] = useState(undefined);
    const [custref, setCustRef] = useState(undefined);

    const parseNumber = function(amount) {
        if (amount === "0") {
            return 0
        }
        return parseFloat(amount.toString().replaceAll(",", ""))
    }

    const recalculateAndSetTotal = function() {
        let dollars = calculateTotal()
        setTotal(dollars)

        dollars = calculateSubtotal()
        setSubtotal(dollars)

        return dollars
    }

    const calculateSubtotal = function() {
        let dollars = 0
        for (let key of Object.keys(products)) {
            dollars += parseNumber(products[key].price)
        }

        return dollars;
    }

    const calculateTotal = function() {
        let dollars = 0
        for (let key of Object.keys(products)) {
            dollars += parseNumber(products[key].price)

            if (transactionFee && transactionFee.type === "amount" && transactionFee.applied_per === "transaction") {
                dollars += parseNumber(transactionFee.amount * products[key].quantity)
            }
        }

        if (transactionFee && transactionFee.type === "percent" && transactionFee.applied_per === "form") {
            dollars += transactionFee.amount * dollars
        }

        if (donation) {
            dollars += parseNumber(donation.price)
        }

        if (donationSettings && donationSettings.isSelected) {
            dollars += (parseNumber(donationSettings.rate) / 100) * dollars
        }

        return dollars;
    }

    const removeProduct = function(id) {
        delete products[id]
        recalculateAndSetTotal()
    }

    const removeDonation = function() {
        setDonation(undefined)
        recalculateAndSetTotal()
    }

    const updateDonation = function(id, name, price) {
        let newDonation = {
            id: id,
            name: name,
            price: parseNumber(price)
        }
        setDonation(newDonation);

        recalculateAndSetTotal()
    }

    const updateProduct = function(id, name, quantity, price, quantityAvailable, amountSold, productActivityInProgressText) {
        let oldProducts = JSON.parse(JSON.stringify(products));
        oldProducts[id] = {
            name: name,
            quantity: quantity,
            price: parseNumber(price) * quantity,
            quantityAvailable: quantityAvailable,
            amountSold: amountSold,
            productActivityInProgressText: productActivityInProgressText
        }
        setProducts(oldProducts);

        recalculateAndSetTotal()
    }

    return (
        <CartContext.Provider
            value={{
                donation,
                updateDonation,
                updateProduct,
                removeDonation,
                removeProduct,
                products,
                transactionFee,
                setTransactionFee,
                total,
                calculateTotal,
                subtotal,
                calculateSubtotal,
                recalculateAndSetTotal,
                custref,
                setCustRef,
                setDonationSettings,
                donationSettings
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
